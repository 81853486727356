<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Viajes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Viajes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="listaConsulta">Categoría Operación</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.tipo_control"
                        style="font-size: 12px; background-color: #fff"
                        @change="getIndex()"
                      >
                        <option
                          v-for="listaConsulta in listasForms.listaConsultas"
                          :key="listaConsulta.numeracion"
                          :value="listaConsulta.numeracion"
                        >
                          {{ listaConsulta.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="escaner">Escaner guia</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.escaner_guia"
                        style="font-size: 12px; background-color: #fff"
                        @change="getIndex()"
                      >
                        <option value=""> Todos </option>
                        <option value="1"> Con Escáner </option>
                        <option value="2"> Sin Escáner </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="escaner">Cierre guia</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.cierre_guia"
                        style="font-size: 12px; background-color: #fff"
                        @change="getIndex()"
                      >
                        <option value=""> Todos </option>
                        <option value="1"> Con Cierre </option>
                        <option value="2"> Sin Cierre </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4"></div>
                  <!-- Botón Buscar -->
                  <div class="col-md-2">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy mt-4 ml-1"
                        @click="getIndex()"
                      >
                        <i class="fas fa-search"></i> Buscar
                      </button>
                      <!-- Botón Limpiar -->
                      <button
                        type="button"
                        class="btn btn-danger mt-4"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i> Limpiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="card-body">
                  <div class="row">
                    <!-- # Viaje -->
                    <div class="form-group col-md-1">
                      <label>N° Viaje</label>
                      <input
                        type="number"
                        v-model="filtros.id"
                        placeholder="N° viaje"
                        label="id"
                        class="form-control form-control-sm"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <!-- # Guía -->
                    <div class="form-group col-md-2">
                      <label>N° Guia</label>
                      <input
                        type="number"
                        v-model="filtros.guia_id"
                        placeholder="N° guia"
                        label="guia_id"
                        class="form-control form-control-sm"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <!-- # Ticket -->
                    <div
                      class="form-group col-md-2"
                      v-if="filtros.tipo_control == 1"
                    >
                      <label>N° Ticket</label>
                      <input
                        type="text"
                        v-model="filtros.ticket_id"
                        placeholder="N° Ticket"
                        label="ticket_id"
                        class="form-control form-control-sm"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <!-- Tipo Combustible -->
                    <div
                      v-if="filtros.tipo_control == 1"
                      class="form-group col-md-2"
                    >
                      <label>Tip. Combustible</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="tipo_combustible"
                        placeholder="Combustible"
                        label="descripcion"
                        :options="listasForms.tiposCombustible"
                        @input="seleccionarTipoCombustible()"
                        multiple
                      ></v-select>
                    </div>
                    <!-- Vehículo -->
                    <div
                      class="form-group"
                      :class="
                        filtros.tipo_control == 1 ? 'col-md-1' : 'col-md-2'
                      "
                    >
                      <label>Vehículo</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Placa"
                        v-model="filtros.vehiculo_id"
                      />
                    </div>
                    <!-- Remolque -->
                    <div
                      class="form-group"
                      :class="
                        filtros.tipo_control == 1 ? 'col-md-1' : 'col-md-2'
                      "
                    >
                      <label>Remolque</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Placa"
                        v-model="filtros.remolque_id"
                      />
                    </div>
                    <!-- Conductor -->
                    <div
                      class="form-group col-md-3"
                      v-if="filtros.tipo_control == 1"
                    >
                      <label>Conductor</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="conductor"
                        placeholder="Nombre"
                        label="nombre"
                        :options="listasForms.conductores"
                        @input="seleccionarConductor()"
                      ></v-select>
                    </div>
                    <template v-else>
                      <div class="form-group col-md-1">
                        <label>Doc. Conductor</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          placeholder="Documento"
                          v-model="filtros.conductor_id"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label>Nombre Conductor</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          placeholder="Nombre Conductor"
                          v-model="filtros.conductor_nombre"
                        />
                      </div>
                    </template>
                    <!-- Producto -->
                    <div class="form-group col-md-2">
                      <label>Producto</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="producto"
                        placeholder="Nombre"
                        label="nombre"
                        :options="listasForms.productos"
                        @input="seleccionarProducto()"
                        multiple
                      ></v-select>
                    </div>
                    <!-- Transportadora -->
                    <div class="form-group col-md-4">
                      <label>Transportadora</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="transportadora"
                        placeholder="Razon social"
                        label="razon_social"
                        :options="listasForms.empresas"
                        @input="seleccionarTransportadora()"
                        multiple
                      ></v-select>
                    </div>
                    <!-- Sitio Cargue -->
                    <div class="form-group col-md-2">
                      <label>Sitio cargue</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="sitio_cargue"
                        placeholder="Sitio cargue"
                        label="nombre"
                        :options="listasForms.sitios"
                        @input="seleccionarSitioCargue()"
                        multiple
                      ></v-select>
                    </div>
                    <!-- Sitio Descargue -->
                    <div
                      class="form-group col-md-2"
                      v-if="filtros.tipo_control == 1"
                    >
                      <label>Sitio Descargue</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="sitio_descargue"
                        placeholder="Sitio descargue"
                        label="nombre"
                        :options="listasForms.sitios"
                        @input="seleccionarSitioDescargue()"
                        multiple
                      ></v-select>
                    </div>
                    <div class="form-group col-md-2" v-else>
                      <label>Sitio descargue</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Nombre"
                        v-model="filtros.sitio_descargue_id"
                      />
                    </div>
                    <!-- Ruta -->
                    <div
                      class="form-group col-md-2"
                      v-if="filtros.tipo_control == 1"
                    >
                      <label>Ruta</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="ruta"
                        placeholder="Nombre"
                        label="nombre"
                        :options="listasForms.rutas"
                        @input="seleccionarRuta()"
                        multiple
                      ></v-select>
                    </div>
                    <!-- Estado -->
                    <div class="form-group col-md-2">
                      <label>Estado</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="estado"
                        placeholder="Descripcion"
                        label="descripcion"
                        :options="listasForms.estados"
                        @input="seleccionarEstado()"
                        multiple
                      >
                      </v-select>
                    </div>
                    <!-- Tipo Último Sitio -->
                    <div class="col-md-2" v-if="filtros.tipo_control == 1">
                      <label>Tipo Último Sitio</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.sitio_type"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="tipo in listasForms.sitio_types"
                          :key="tipo.numeracion"
                          :value="tipo.numeracion"
                        >
                          {{ tipo.descripcion }}
                        </option>
                      </select>
                    </div>
                    <!-- Punto Gestión Último -->
                    <div
                      class="form-group col-md-2"
                      v-if="filtros.sitio_type == 2"
                    >
                      <label>Último Punto Gestión</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="ultimo_punto"
                        placeholder="Punto Gestión"
                        label="nombre"
                        :options="listasForms.puntos_gestion"
                        @input="seleccionarSitio"
                      ></v-select>
                    </div>
                    <!-- Código Aceptación -->
                    <div
                      class="form-group col-md-2"
                      v-if="filtros.tipo_control == 1"
                    >
                      <label>N° Codigo de Aceptación</label>
                      <input
                        type="number"
                        v-model="filtros.aceptacion"
                        placeholder="Codigo de Aceptación"
                        class="form-control form-control-sm"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <!-- Sitio Último -->
                    <div
                      class="form-group col-md-2"
                      v-if="filtros.sitio_type == 1"
                    >
                      <label>Último Sitio</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="ultimo_punto"
                        placeholder="Sitio"
                        label="nombre"
                        :options="listasForms.sitios"
                        @input="seleccionarSitio"
                      ></v-select>
                    </div>
                    <!-- Rangos Fechas -->
                    <div class="form-group col-md-4">
                      <label>Rango de fechas de expedicion</label>
                      <div class="row">
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_inicio"
                            @input="validaFechas()"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_fin"
                            @input="validaFechas()"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- Fecha Expedición -->
                    <!--   <div class="col-md-2">
                      <div class="form-group">
                        <label for="fecha_expedicion">Fecha Expedición</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_expedicion"
                        />
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="card">
                  <div class="card-body table-responsive">
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          style="font-size: 9px; overflow-x: auto"
                          id="viajes"
                          class="
                            table
                            table-bordered
                            table-striped
                            table-hover
                            table-sm
                            team-roster-table
                            text-nowrap                        
                          "
                        >
                          <thead class="thead">
                            <tr>
                              <th></th>
                              <th>#</th>
                              <th>Guía</th>
                              <th>Liquidación Guía</th>
                              <th>Usuario Liquidación Guía</th>
                              <th>Ticket</th>
                              <th>Vehículo</th>
                              <th>Remolque</th>
                              <th>Conductor</th>
                              <th>Transportadora</th>
                              <th>Ruta</th>
                              <th>Producto</th>
                              <th>Sitio cargue</th>
                              <th>Sitio descargue</th>
                              <th>Último punto</th>
                              <th>Cita Cargue</th>
                              <th>Fecha Expedición</th>
                              <th>Fecha Salida</th>
                              <th>Estado</th>
                              <th>Cierre masivo</th>
                              <th>Escáner Guía</th>
                              <th v-if="filtros.tipo_control == 1">
                                Codigo de Aceptación
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in viajes.data" :key="item.id">
                              <td>
                                <!-- Split dropright button -->
                                <div
                                  v-if="item.tipo_operacion == 1"
                                  :class="
                                    viajes.data.length == 1
                                      ? 'dropop'
                                      : 'dropright'
                                  "
                                  style="position: static; z-index: auth"
                                >
                                  <button
                                    type="button"
                                    class="btn btn-dark btn-sm"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="fas fa-align-justify"></i>
                                  </button>
                                  <div class="dropdown-menu">
                                    <a
                                      class="dropdown-item"
                                      data-toggle="modal"
                                      data-target="#modal-form-mapa"
                                      style="cursor: pointer"
                                      v-if="
                                        $store.getters.can(
                                          'hidrocarburos.ventanasOperaciones.generarMapa'
                                        )
                                      "
                                      @click="
                                        llenarModalMapa(item, item.cita_cargue)
                                      "
                                    >
                                      <i class="fa fa-map-marker-alt"></i>
                                      Mapa
                                    </a>
                                    <div v-if="item.guia">
                                      <div
                                        v-if="
                                          item.guia.cierre_guias.length == 0
                                        "
                                      >
                                        <a
                                          class="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#modal-cambio-ventana"
                                          style="cursor: pointer"
                                          @click="
                                            llenarModalVentana(item) &&
                                              $store.getters.can(
                                                'hidrocarburos.viajes.cambiarVentana'
                                              )
                                          "
                                        >
                                          <i class="fas fa-exchange-alt"></i>
                                          Actualizar ventana
                                        </a>
                                        <a
                                          class="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#modal-form-cambio-trinomios"
                                          style="cursor: pointer"
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.cambiarVehiculo'
                                            )
                                          "
                                          @click="
                                            llenarModalEntidad(item, 'Vehículo')
                                          "
                                        >
                                          <i class="fas fa-exchange-alt"></i>
                                          Cambiar vehículo
                                        </a>
                                        <a
                                          class="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#modal-form-cambio-trinomios"
                                          style="cursor: pointer"
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.cambiarRemolque'
                                            )
                                          "
                                          @click="
                                            llenarModalEntidad(item, 'Remolque')
                                          "
                                        >
                                          <i class="fas fa-exchange-alt"></i>
                                          Cambiar remolque
                                        </a>
                                        <a
                                          class="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#modal-form-cambio-trinomios"
                                          style="cursor: pointer"
                                          @click="
                                            llenarModalEntidad(
                                              item,
                                              'Conductor'
                                            )
                                          "
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.cambiarConductor'
                                            )
                                          "
                                        >
                                          <i class="fas fa-exchange-alt"></i>
                                          Cambiar conductor
                                        </a>
                                        <a
                                          class="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#modal-form-cambio-trinomios"
                                          style="cursor: pointer"
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.cambiarRuta'
                                            )
                                          "
                                          @click="
                                            llenarModalEntidad(item, 'Ruta')
                                          "
                                        >
                                          <i class="fas fa-exchange-alt"></i>
                                          Cambiar ruta
                                        </a>
                                        <a
                                          class="dropdown-item"
                                          style="cursor: pointer"
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.actualizarGuia'
                                            )
                                          "
                                          @click="editGuia(item)"
                                        >
                                          <i class="fas fa-marker"></i>
                                          Actualizar guía
                                        </a>
                                        <a
                                          class="dropdown-item"
                                          style="cursor: pointer"
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.anularCambioGuia'
                                            )
                                          "
                                          @click="anularCambio(item)"
                                        >
                                          <i class="fas fa-ban"></i>
                                          Anular Guía - Cambio
                                        </a>
                                        <a
                                          id="botonImp"
                                          type="button"
                                          class="dropdown-item"
                                          style="cursor: pointer"
                                          @click="
                                            printGuia(item.guia.det_guias[0].id)
                                          "
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.printGuia'
                                            ) &&
                                              item.guia !== null &&
                                              item.guia !== undefined
                                          "
                                        >
                                          <i class="fas fa-print"></i>
                                          Imprimir Guía
                                        </a>
                                        <a
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.indexPdf'
                                            )
                                          "
                                          class="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#modal-escaner-guia"
                                          style="cursor: pointer"
                                          @click="modalEscanerGuía(item)"
                                        >
                                          <i class="fas fa-file-pdf"></i>
                                          Escáner Guía
                                        </a>
                                        <a
                                          class="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#modal-escaner-acta"
                                          style="cursor: pointer"
                                          @click="modalEscanerActa(item)"
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.getActa'
                                            ) && item.bono != null
                                          "
                                        >
                                          <i class="fas fa-file-pdf"></i>
                                          Escáner Acta
                                        </a>
                                        <a
                                          id="botonImp"
                                          type="button"
                                          class="dropdown-item"
                                          style="cursor: pointer"
                                          :href="
                                            uri_docs + item.bono.link_soporte
                                          "
                                          target="_blank"
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.printActa'
                                            ) &&
                                              item.bono != null &&
                                              item.bono.link_soporte
                                          "
                                        >
                                          <i class="fas fa-print"></i>
                                          Imprimir Acta
                                        </a>
                                        <a
                                          id="botonImp"
                                          type="button"
                                          class="dropdown-item"
                                          style="cursor: pointer"
                                          @click="printActa(item.id)"
                                          v-if="
                                            $store.getters.can(
                                              'hidrocarburos.viajes.printActa'
                                            ) &&
                                              item.bono != null &&
                                              !item.bono.link_soporte
                                          "
                                        >
                                          <i class="fas fa-print"></i>
                                          Imprimir Acta
                                        </a>
                                      </div>
                                    </div>
                                    <div v-if="item.ticket !== null">
                                      <a
                                        class="dropdown-item"
                                        style="cursor: pointer"
                                        v-if="
                                          item.ticket != null &&
                                            item.ticket &&
                                            $store.getters.can(
                                              'hidrocarburos.viajes.actualizarTicket'
                                            )
                                        "
                                        @click="editTicket(item)"
                                      >
                                        <i class="fas fa-marker"></i>
                                        Actualizar Ticket
                                      </a>
                                      <a
                                        id="botonImp"
                                        type="button"
                                        class="dropdown-item"
                                        style="cursor: pointer"
                                        @click="printTiket(item.ticket_id)"
                                        v-if="
                                          $store.getters.can(
                                            'hidrocarburos.viajes.generarPdfTickets'
                                          ) &&
                                            item.ticket !== null &&
                                            item.ticket !== undefined
                                        "
                                      >
                                        <i class="fas fa-print"></i>
                                        Imprimir Ticket
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-else
                                  :class="
                                    viajes.data.length == 1
                                      ? 'dropop'
                                      : 'dropright'
                                  "
                                  style="position: static; z-index: auth"
                                >
                                  <button
                                    type="button"
                                    class="btn btn-dark btn-sm"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="fas fa-align-justify"></i>
                                  </button>
                                  <div class="dropdown-menu">
                                    <div v-if="item.guia">
                                      <a
                                        class="dropdown-item"
                                        style="cursor: pointer"
                                        v-if="
                                          $store.getters.can(
                                            'hidrocarburos.viajes.actualizarGuia'
                                          )
                                        "
                                        @click="editGuia(item)"
                                      >
                                        <i class="fas fa-marker"></i>
                                        Actualizar guía
                                      </a>
                                      <a
                                        id="botonImp"
                                        type="button"
                                        class="dropdown-item"
                                        style="cursor: pointer"
                                        @click="anularCambioGuia(item)"
                                        v-if="
                                          $store.getters.can(
                                            'hidrocarburos.ventanasOperaciones.anularCambioGuia'
                                          ) &&
                                            item.guia !== null &&
                                            item.guia !== undefined
                                        "
                                      >
                                        <i class="fas fa-ban"></i>
                                        Anular Guía - Cambio
                                      </a>
                                      <a
                                        id="botonImp"
                                        type="button"
                                        class="dropdown-item"
                                        style="cursor: pointer"
                                        @click="printGuiaRegalias(item.id)"
                                        v-if="
                                          $store.getters.can(
                                            'hidrocarburos.viajes.printGuia'
                                          ) &&
                                            item.guia !== null &&
                                            item.guia !== undefined
                                        "
                                      >
                                        <i class="fas fa-print"></i>
                                        Imprimir Guía
                                      </a>
                                      <a
                                        v-if="
                                          $store.getters.can(
                                            'hidrocarburos.viajes.indexPdf'
                                          )
                                        "
                                        class="dropdown-item"
                                        data-toggle="modal"
                                        data-target="#modal-escaner-guia"
                                        style="cursor: pointer"
                                        @click="modalEscanerGuía(item)"
                                      >
                                        <i class="fas fa-file-pdf"></i>
                                        Escáner Guía
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  class="btn btn-sm btn-default py-0"
                                  data-toggle="modal"
                                  data-target="#modal-form-detViaje"
                                  @click="
                                    llenarModalDetViaje(
                                      item.id,
                                      item.tipo_operacion
                                    )
                                  "
                                >
                                  {{ item.id }}
                                </button>
                              </td>
                              <td
                                v-if="
                                  item.guia !== null && item.guia !== undefined
                                "
                              >
                                {{ item.guia.numero }}-{{
                                  item.guia.digito_verificacion
                                }}
                              </td>
                              <td v-else class="text-center">
                                <span class="badge badge-danger"
                                  >Sin asignar</span
                                >
                              </td>
                              <td
                                class="text-center"
                                v-if="item.liquidacion_guia"
                              >
                                <span class="badge bg-success"
                                  >Ya se puede generar guía</span
                                >
                              </td>
                              <td class="text-center" v-else>
                                <span class="badge bg-danger"
                                  >Sin Liquidar Guía</span
                                >
                              </td>
                              <td
                                class="text-center"
                                v-if="item.liquidacion_guia"
                              >
                                <span class="badge bg-navy">{{
                                  item.liquidacion_guia.user.name
                                }}</span>
                              </td>
                              <td class="text-center" v-else>
                                <span class="badge bg-danger"
                                  >Sin Liquidar Guía</span
                                >
                              </td>
                              <td
                                v-if="
                                  item.ticket !== null &&
                                    item.ticket !== undefined
                                "
                              >
                                {{ item.ticket.numero_ticket }}
                              </td>
                              <td v-else class="text-center">
                                <span class="badge badge-danger"
                                  >Sin asignar</span
                                >
                              </td>
                              <td v-if="item.tipo_operacion == 1">
                                <div>
                                  {{ item.vehiculo.placa }}
                                </div>
                                <span
                                  v-if="item.vehiculo != null"
                                  class="badge ml-1 mr-1"
                                  :class="
                                    item.vehiculo.tipo_combustible == 1
                                      ? 'badge-warning'
                                      : 'badge-info'
                                  "
                                >
                                  {{ item.vehiculo.nCombustible }}
                                </span>
                              </td>
                              <td v-else>{{ item.vehiculo }}</td>
                              <td
                                v-if="
                                  item.remolque != null &&
                                    item.tipo_operacion == 1
                                "
                              >
                                {{ item.remolque.placa }}
                              </td>
                              <td
                                v-else-if="
                                  item.remolque != null &&
                                    item.tipo_operacion != 1
                                "
                              >
                                {{ item.remolque }}
                              </td>
                              <td v-else></td>
                              <td v-if="item.tipo_operacion == 1">
                                <div>
                                  {{ item.conductor.nombres }}
                                  {{ item.conductor.apellidos }}
                                </div>
                                <span class="badge badge-info">
                                  {{ item.conductor.numero_documento }}
                                </span>
                              </td>
                              <td v-else>
                                <div>
                                  {{ item.conductor }}
                                </div>
                                <span class="badge badge-info">
                                  {{ item.documento_conductor }}
                                </span>
                              </td>
                              <td
                                v-if="
                                  item.tipo_operacion == 1 &&
                                    item.transportadora
                                "
                              >
                                {{ item.transportadora.razon_social }}
                              </td>
                              <td
                                v-else-if="
                                  item.tipo_operacion != 1 &&
                                    item.transportadora
                                "
                              >
                                {{ item.transportadora.razon_social }}
                              </td>
                              <td v-else></td>
                              <td v-if="item.ruta != null">
                                {{ item.ruta.nombre }}
                              </td>
                              <td v-else></td>
                              <td v-if="item.producto_liquido != null">
                                {{ item.producto_liquido.nombre }}
                              </td>
                              <td v-else-if="item.ticket">
                                {{ item.ticket.producto.nombre }}
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  item.sitio_cargue != null &&
                                    item.tipo_operacion == 1
                                "
                              >
                                {{ item.sitio_cargue.nombre }}
                              </td>
                              <td
                                v-else-if="
                                  item.sitio_origen != null &&
                                    item.tipo_operacion != 1
                                "
                              >
                                {{ item.sitio_origen.nombre }}
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  item.sitio_descargue != null &&
                                    item.tipo_operacion == 1
                                "
                              >
                                {{ item.sitio_descargue.nombre }}
                              </td>
                              <td
                                v-else-if="
                                  item.sitio_destino != null &&
                                    item.tipo_operacion != 1
                                "
                              >
                                {{ item.sitio_destino }}
                              </td>
                              <td v-else></td>
                              <td v-if="item.sitio != null">
                                {{ item.sitio.nombre }}
                              </td>
                              <td v-else></td>
                              <td v-if="item.cita_cargue != null">
                                {{ item.cita_cargue }}
                              </td>
                              <td v-else></td>
                              <td
                                class="text-center"
                                v-if="item.guia != null || item.ticket != null"
                              >
                                <div
                                  v-if="
                                    item.guia != null &&
                                      item.tipo_operacion == 1
                                  "
                                >
                                  {{ item.guia.det_guias[0].fecha_expedicion }}
                                </div>
                                <div
                                  v-else-if="
                                    item.guia != null &&
                                      item.tipo_operacion != 1
                                  "
                                >
                                  {{ item.fecha_expedicion }}
                                </div>
                                <div v-else>
                                  {{ item.ticket.fecha_cargue }}
                                </div>
                              </td>
                              <td v-else></td>
                              <td
                                class="text-center"
                                v-if="item.guia != null || item.ticket != null"
                              >
                                <div
                                  v-if="
                                    item.guia != null &&
                                      item.tipo_operacion == 1
                                  "
                                >
                                  {{ item.guia.det_guias[0].fecha_cargue }}
                                  {{ item.guia.det_guias[0].hora_cargue }}
                                </div>
                                <div
                                  v-else-if="
                                    item.guia != null &&
                                      item.tipo_operacion != 1
                                  "
                                >
                                  {{ item.fecha_cargue }}
                                  {{ item.hora_cargue }}
                                </div>
                                <div v-else>
                                  {{ item.ticket.fecha_salida_cargue }}
                                </div>
                              </td>
                              <td v-else></td>
                              <td class="text-center">
                                <span
                                  class="badge"
                                  :class="
                                    item.estado == 1 || item.estado == 7
                                      ? 'badge-primary'
                                      : item.estado == 6 || item.estado == 9
                                      ? 'bg-navy'
                                      : item.estado == 5 || item.estado == 10
                                      ? 'badge-info'
                                      : item.estado == 4 || item.estado == 2
                                      ? 'badge-success'
                                      : item.estado == 3
                                      ? 'badge-danger'
                                      : 'badge-dark'
                                  "
                                >
                                  {{ item.estado }}. {{ item.estadoViaje }}
                                </span>
                              </td>
                              <td
                                class="text-center"
                                v-if="item.tipo_operacion == 1"
                              >
                                <div v-if="item.guia">
                                  <i
                                    v-if="item.guia.cierre_guias.length == 0"
                                    class="fa fa-times-circle text-danger"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="
                                    Sin cierre masivo
                                  "
                                  ></i>
                                  <i
                                    v-else
                                    class="fa fa-check text-success"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="
                                    Con cierre masivo
                                  "
                                  ></i>
                                </div>
                                <div v-else>
                                  <i
                                    class="fa fa-times-circle text-danger"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="
                                    Sin cierre masivo
                                  "
                                  ></i>
                                </div>
                              </td>
                              <td v-else>
                                <div>
                                  <i class="fa fa-times-circle text-danger"></i>
                                  N/A
                                </div>
                              </td>
                              <td class="text-center">
                                <a
                                  target="_blank"
                                  :href="uri_docs + item.escaner_guia"
                                  v-if="item.escaner_guia"
                                >
                                  <i
                                    class="fa fa-check text-success"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                  ></i>
                                </a>
                                <i
                                  v-else
                                  class="fa fa-times-circle text-danger"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                ></i>
                              </td>
                              <td
                                class="text-center"
                                v-if="filtros.tipo_control == 1"
                              >
                                <div
                                  v-if="
                                    item.guia &&
                                      item.aceptacion == null &&
                                      item.guia.cierre_guias != undefined
                                  "
                                >
                                  <button
                                    title="Agregar Codigo De Aceptación"
                                    type="button"
                                    class="btn btn-sm bg-info text-white"
                                    data-toggle="modal"
                                    data-target="#assignNumberModal"
                                    v-if="item.guia.cierre_guias.length > 0"
                                    @click="llenarModalAceptacion(item.id)"
                                  >
                                    <i class="fas fa-clipboard-check"></i>
                                  </button>
                                </div>
                                <div
                                  v-else-if="
                                    item.guia && item.aceptacion !== null
                                  "
                                >
                                  <span
                                    class="badge badge-info"
                                    style="font-size: 10px;"
                                  >
                                    {{ item.aceptacion }}
                                  </span>
                                </div>
                                <div v-else>
                                  <span class="badge badge-danger">
                                    Sin Guía
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Modal -->
                <div
                  class="modal fade"
                  id="assignNumberModal"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                  style="background-color: #00000082"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <div class="modal-dialog modal-s" role="dialog">
                    <div class="modal-content">
                      <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                        <h5
                          class="modal-title modal-title text-white"
                          id="assignNumberModalLabel"
                        >
                          Asignar Codigo De Aceptación
                        </h5>
                        <button
                          type="button"
                          class="close text-white"
                          data-dismiss="modal"
                          aria-label="Close"
                          id="close-modal"
                          ref="closeModal"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <!-- Aquí puedes agregar los campos y elementos necesarios para asignar el número -->
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Codigo De Aceptación"
                          v-model="formAceptacion.aceptacion"
                        />
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary"
                          style="cursor: pointer"
                          @click="guardarCodigoAceptacion()"
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="viajes.total">
                    Mostrando del <b>{{ viajes.from }}</b> al
                    <b>{{ viajes.to }}</b> de un total:
                    <b>{{ viajes.total }}</b> Registros
                  </div>
                  <div class="float-left" v-else>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </div>
                  <pagination
                    :data="viajes"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    class="float-right m-0"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ViajesDetViaje ref="ViajesDetViaje" />
        <VentanaOperacionesMapa ref="VentanaOperacionesMapa" />
        <ViajeCambioVentana ref="ViajeCambioVentana" />
        <VentanaOperacionesCambios ref="VentanaOperacionesCambios" />
        <ViajeEscanerGuia ref="ViajeEscanerGuia" />
        <VentanaOperacionesEscanerActa ref="VentanaOperacionesEscanerActa">
        </VentanaOperacionesEscanerActa>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import ViajesDetViaje from "../viajes/ViajesDetViaje";
import ViajeCambioVentana from "../viajes/ViajeCambioVentana";
import VentanaOperacionesMapa from "../ventanasOperaciones/VentanaOperacionesMapa";
import VentanaOperacionesCambios from "../ventanasOperaciones/VentanaOperacionesCambios";
import ViajeEscanerGuia from "./ViajeEscanerGuia";
import vSelect from "vue-select";
import axios from "axios";
import Loading from "../../../../components/Loading";
import VentanaOperacionesEscanerActa from "./../ventanasOperaciones/VentanaOperacionesEscanerActa";
export default {
  name: "ViajeIndex",
  components: {
    Loading,
    pagination,
    ViajesDetViaje,
    ViajeCambioVentana,
    VentanaOperacionesMapa,
    VentanaOperacionesCambios,
    ViajeEscanerGuia,
    vSelect,
    VentanaOperacionesEscanerActa,
  },
  data() {
    return {
      cargando: false,
      viajes: {},
      filtros: {
        tipo_control: 1,
      },
      conductor: null,
      vehiculo: null,
      remolque: null,
      ultimo_punto: null,
      aceptacion: null,
      ruta: [],
      estado: [],
      producto: [],
      sitio_cargue: [],
      transportadora: [],
      sitio_descargue: [],
      tipo_combustible: [],
      formAceptacion: {
        aceptacion: null,
        viaje_id: null,
      },
      listasForms: {
        estados: [],
        sitios: [],
        conductores: [],
        productos: [],
        rutas: [],
        cabezotes: [],
        tiposCombustible: [],
        remolques: [],
        empresas: [],
        puntos_gestion: [],
        sitio_types: [],
        listaConsultas: [],
        aceptacion: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/viajes?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.viajes = response.data;
        });
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_inicio);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicio no puede ser mayor a la fecha fin...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    limpiar() {
      this.filtros.id = "";
      this.filtros.guia_id = "";
      this.filtros.ticket_id = "";
      this.filtros.vehiculo_id = "";
      this.filtros.remolque_id = "";
      this.filtros.conductor_id = "";
      this.filtros.tipo_combustible = "";
      this.filtros.producto_id = "";
      this.filtros.transportadora_id = "";
      this.filtros.sitio_cargue_id = "";
      this.filtros.sitio_descargue_id = "";
      this.filtros.ruta_id = "";
      this.filtros.estado = "";
      this.filtros.sitio_type = "";
      this.filtros.fecha_inicio = "";
      this.filtros.fecha_fin = "";
      this.filtros.fecha_expedicion = "";
      this.filtros.aceptacion = "";
      this.filtros.escaner_guia = "";
      this.filtros.cierre_guia = "";
      this.conductor = null;
      this.vehiculo = null;
      this.remolque = null;
      this.transportadora = [];
      this.tipo_combustible = [];
      this.ruta = [];
      this.producto = [];
      this.estado = [];
      this.sitio_cargue = [];
      this.sitio_descargue = [];
      this.ultimo_punto = null;
      this.getIndex();
    },

    getSitioType() {
      // sitio 1, punto gestion 2
      axios.get("/api/lista/25").then((response) => {
        this.listasForms.sitio_types = response.data;
      });
    },

    buscarCabezotes() {
      let me = this;
      var url = "api/admin/vehiculos/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.cabezotes = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTiposCombustibles() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.tiposCombustible = response.data;
      });
    },

    buscarRemolques() {
      let me = this;
      var url = "api/admin/remolques/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.remolques = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRutas() {
      let me = this;
      var url = "api/admin/rutas/lista";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.rutas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarProductos() {
      let me = this;
      var url = "api/hidrocarburos/Productos/lista";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.productos = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarConductores() {
      let me = this;
      var url = "api/admin/conductores/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.conductores = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    llenarModalMapa(item, fecha) {
      this.$refs.VentanaOperacionesMapa.llenar_modal_mapa(item, fecha);
    },

    llenarModalVentana(viaje_id) {
      this.$refs.ViajeCambioVentana.llenar_modal_cambio_ventana(viaje_id);
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarPuntosGestion() {
      let me = this;
      var url = "api/admin/puntos_gestion/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.puntos_gestion = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    seleccionarTipoCombustible() {
      this.filtros.tipo_combustible = this.tipo_combustible
        ? this.tipo_combustible.map((tipo) => tipo.numeracion)
        : null;
    },

    seleccionarRemolque() {
      if (this.remolque != null) {
        this.filtros.remolque_id = this.remolque.id;
      } else {
        this.filtros.remolque_id = null;
      }
    },

    seleccionarTransportadora() {
      this.filtros.transportadora_id = this.transportadora
        ? this.transportadora.map((trans) => trans.id)
        : null;
    },

    seleccionarConductor() {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      } else {
        this.filtros.conductor_id = null;
      }
    },

    seleccionarRuta() {
      this.filtros.ruta_id = this.ruta ? this.ruta.map((s) => s.id) : null;
    },

    seleccionarProducto() {
      this.filtros.producto_id = this.producto
        ? this.producto.map((s) => s.id)
        : null;
    },

    seleccionarSitioCargue() {
      this.filtros.sitio_cargue_id = this.sitio_cargue
        ? this.sitio_cargue.map((s) => s.id)
        : null;
    },

    seleccionarSitioDescargue() {
      this.filtros.sitio_descargue_id = this.sitio_descargue
        ? this.sitio_descargue.map((s) => s.id)
        : null;
    },

    seleccionarEstado() {
      this.filtros.estado = this.estado
        ? this.estado.map((s) => s.numeracion)
        : null;
    },

    seleccionarSitio() {
      if (this.filtros.sitio_type == 1 || this.filtros.sitio_type == 2) {
        if (this.ultimo_punto != null) {
          this.filtros.sitio_id = this.ultimo_punto.id;
        } else {
          this.filtros.sitio_id = null;
        }
      }
    },

    limpiarModal() {
      this.aceptacion = null;
      this.formAceptacion = {
        aceptacion: null,
        viaje_id: null,
      };
    },

    guardarCodigoAceptacion() {
      let params = this.formAceptacion;
      this.$swal({
        title: "Esta seguro de crear un codigo de aceptación?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar!!",
      }).then((result) => {
        if (result.value) {
          axios({
            method: "POST",
            url: "/api/hidrocarburos/viajes/codigoAceptacion",
            data: params,
          })
            .then((response) => {
              this.$refs.closeModal.click();
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "La petición se creo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    edit(viaje) {
      return this.$router.push({
        name: "/Hidrocarburos/ViajeForm",
        params: {
          accion: "Editar",
          acto: 2,
          data_edit: viaje,
        },
      });
    },

    llenarModalDetViaje(viaje, tipo_control) {
      this.$refs.ViajesDetViaje.llenar_modal_detViaje(viaje, tipo_control);
    },

    llenarModalAceptacion(viaje) {
      this.limpiarModal();
      this.formAceptacion.viaje_id = viaje;
    },

    getEstados() {
      axios.get("/api/lista/39").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getListaConsulta() {
      axios.get("/api/lista/47").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].numeracion != 2) {
            this.listasForms.listaConsultas.push(response.data[i]);
          }
        }
      });
    },

    llenarModalEntidad(viaje, entidad) {
      this.$refs.VentanaOperacionesCambios.getIndex(viaje, 2, entidad);
    },

    modalEscanerGuía(viaje) {
      let viaje_type =
        this.filtros.tipo_control == 1
          ? "App\\Viaje"
          : this.filtros.tipo_control == 3
          ? "App\\DetGuiaRegalias"
          : null;
      this.$refs.ViajeEscanerGuia.llenar_modal_escaner_guia(viaje, viaje_type);
    },

    modalEscanerActa(viaje) {
      this.$refs.VentanaOperacionesEscanerActa.llenar_modal_escaner_acta(viaje);
    },

    editGuia(guia) {
      if (this.filtros.tipo_control == 1) {
        return this.$router.push({
          name: "/Hidrocarburos/DetGuiasForm",
          params: {
            accion: "Actualizar",
            actoGuia: 4,
            data_edit: guia,
          },
        });
      } else if (this.filtros.tipo_control == 3) {
        return this.$router.push({
          name: "/Hidrocarburos/DetGuiasRegaliasForm",
          params: {
            accion: "Actualizar",
            actoGuia: 4,
            data_edit: guia,
          },
        });
      } else {
        return this.$router.push({
          name: "/Hidrocarburos/DetGuiasVentasTercerosForm",
          params: {
            accion: "Actualizar",
            actoGuia: 4,
            data_edit: guia,
          },
        });
      }
    },

    anularCambio(viaje) {
      return this.$router.push({
        name: "/Hidrocarburos/DetGuiasForm",
        params: {
          accion: "Cambiar",
          actoGuia: 3,
          data_edit: viaje,
          sitio_type: 1,
          sitio_id: viaje.sitio_cargue_id,
          sitio_type2: "App\\Sitio",
          tipo_control: 1,
          volver_viaje: 1,
        },
      });
    },

    anularCambioGuia(viaje) {
      if (this.filtros.tipo_control == 3) {
        return this.$router.push({
          name: "/Hidrocarburos/DetGuiasRegaliasForm",
          params: {
            accion: "Cambiar",
            actoGuia: 3,
            data_edit: viaje,
            sitio_type: 1,
            sitio_id: viaje.sitio_origen_id,
            sitio_type2: "App\\Sitio",
            tipo_control: 3,
            volver_viaje: 1,
          },
        });
      } else {
        return this.$router.push({
          name: "/Hidrocarburos/DetGuiasVentasTercerosForm",
          params: {
            accion: "Cambiar",
            actoGuia: 3,
            data_edit: viaje,
            sitio_type: 1,
            sitio_id: viaje.sitio_origen_id,
            sitio_type2: "App\\Sitio",
            tipo_control: 3,
            volver_viaje: 1,
          },
        });
      }
    },

    editTicket(ticket) {
      return this.$router.push({
        name: "/Hidrocarburos/DetTicketsForm",
        params: {
          accion: "Actualizar",
          actoTicket: 4,
          data_edit: ticket,
          sitio_type: 1,
          sitio_id: ticket.sitio_cargue_id,
          sitio_type2: "App\\Sitio",
          tipo_control: 1,
        },
      });
    },

    printTiket(idTicket) {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/detalleTickets/pdf/" + idTicket,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    printGuiaRegalias(idDetGuia) {
      window.open(
        process.env.VUE_APP_API_URL + "/toPrint/guiaRegalias/" + idDetGuia,
        "_blank"
      );
    },

    printGuia(idDetGuia) {
      window.open(
        process.env.VUE_APP_API_URL + "/toPrint/guia/" + idDetGuia,
        "_blank"
      );
    },

    printActa(viaje_id) {
      axios
        .get(`/api/hidrocarburos/detalleGuias/printActa`, {
          params: { viaje_id },
        })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.buscarCabezotes();
    this.getTiposCombustibles();
    this.buscarRemolques();
    this.buscarTransportadora();
    this.buscarConductores();
    this.buscarProductos();
    this.buscarRutas();
    this.getSitioType();
    this.buscarSitios();
    this.buscarPuntosGestion();
    this.getListaConsulta();
  },
};
</script>
